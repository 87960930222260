<template>
  <div class="trainplan">
    <div class="trainplan-wrapper">
      <div class="introduction">
        <h3>项目介绍</h3>
        <div class="describe flex-layout">
          <img src="../../assets/images/course/course-cover01.png" alt="" class="cover">
          <div>
            <p class="name"><span class="caption">项目名称：</span> {{info.name || '--'}}</p>
            <p class="general"><span class="caption">项目概况：</span>{{info.introduction || '--'}}</p>
          </div>
        </div>
      </div>
      <div class="trainplan-main">
        <el-tabs v-model="activeName" class="tabs">
          <el-tab-pane label="培养计划" name="first">
            <div class="tab-contariner">
              <div class="content" v-html="info.training"></div>
            </div>
            <Empty :show="!info.training"></Empty>
          </el-tab-pane>
          <el-tab-pane label="考核标准" name="second">
            <div class="tab-contariner">
              <div class="content" v-html="info.criteria"></div>
            </div>
            <Empty :show="!info.criteria"></Empty>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: 'first',
        info: {},
      }
    },
    methods: {
      handleClick(row) {
        // console.log(row);
      },
      async getInfo() {
        let projectId = this.$route.params.id
        let resData = await this.$Api.Home.getTrainPlanInfo(projectId)
        // console.log(resData);
        this.info = resData.data;
      }
    },
    mounted() {
      this.getInfo();
    }
  }
</script>

<style lang="less" scoped>
  .trainplan {
    // background-color: #F9F9F9;

    .trainplan-wrapper {
      width: 990px;
      margin: 0 auto;

      .introduction {
        padding: 0px 48px;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 10px 0px #F5F8FA;

        h3 {
          font-size: 13px;
          line-height: 50px;

          &::before {
            content: ' ';
            display: inline-block;
            width: 4px;
            height: 21px;
            background: linear-gradient(180deg, #186FF5, #307CF0);
            border-radius: 2px;
            margin-right: 11px;
            vertical-align: -5px;
          }
        }

        .describe {
          padding: 31px 16px;
          border-top: 1px solid #f5f4f4;
          font-size: 12px;
          line-height: 20px;

          .cover {
            width: 360px;
            height: 196px;
            border-radius: 8px;
            margin-right: 37px;
          }

          span.caption {
            font-weight: bold;
          }

          .name {
            margin: 12px 0 14px;
          }

        }
      }

      .trainplan-main {
        box-shadow: 0px 2px 10px 0px #F5F8FA;
        border-radius: 10px;
        margin-top: 35px;
      }

      .tabs {
        margin: 19px 0 155px;
        padding: 0 47px;
        border-radius: 10px;
        background-color: #fff;
      }

      /deep/ .el-tabs__header {
        background-color: #fff;
        margin: 0;

        .el-tabs__item {
          padding: 0 40px !important;
          font-size: 14px;
          // font-weight: bold;
          height: 49px;
          line-height: 49px;
        }

        .el-tabs__nav-wrap.is-top {
          padding: 0;
        }

        .el-tabs__item:hover {
          color: #508EF9;
        }

        .el-tabs__item.is-active {
          color: #508EF9;

        }
      }

      /deep/ .el-tabs__active-bar {
        height: 1px;
        background-color: #508EF9;
        padding: 0 25px;
        margin-left: -25px;
      }

      /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f2f2f2;
      }

      /deep/ .el-tabs__nav.is-stretch>* {
        flex: 0 0 15%;
      }

      .content {
        width: 100%;
        padding: 24px 15px 50px;
      }
    }

    @media screen and (min-width:1250px) {
      .trainplan-wrapper {
        width: 1200px;

        .introduction {
          padding: 0 56px;


          h3 {
            font-size: 16px;
            line-height: 60px;
          }
        }

        .describe {
          padding: 37px 19px;
          font-size: 14px;
          line-height: 24px;

          .cover {
            width: 436px;
            height: 237px;
            border-radius: 10px;
            margin-right: 45px;
          }

          .name {
            margin: 15px 0 18px;
          }

          .name,
          .general {
            font-size: 14px;
          }

        }

        /deep/ .el-tabs__header {

          .el-tabs__item {
            font-size: 16px;
            height: 59px;
            line-height: 59px;
          }
        }

        .content {
          padding: 29px 18px 61px;
        }
      }

    }
  }
</style>